@import "./fonts.css";

.is-family-primary {
	font-family: consolas;
}

.is-family-secondary {
	font-family: lores-15;
}

/*========================================  */

.about-holder{
	width:100%;
	height:100%;
	color:white;
	display: flex;
	align-items: center;
	flex-direction: column;
	margin-bottom: 5%; 
}

.section {
	margin: auto;
	margin-top:4%;
	width:80%;
	height: 30vw;
	display: flex;
	align-items: center;
	/* flex-direction: row; */
}


.image-item {
	width:35%;
	/* height:100%; */
	display: inline;
}

.content-align {
	display: flex;
	/* flex-direction: row; */
	align-items: center;
	height:100%;
}

.container {
	display: flex;
	align-items: center;
	flex-direction: column;
}

.one-title {
	font-size: 4vw;
	margin-bottom: 4%;
}

.one-text {
	font-size:.9vw;
	width:90%;
	margin-top: auto;
}





















