
@import './fonts.css';


:root {
	--primary: #935be9;
	--primary-hover:#9864e8;
}


.highlight {
	color: var(--primary);
}

.whitelist-body {
	
	/* height:95vh; */
	width:100%;
	display: flex;
	align-items: center;
	justify-content: center;
}


.main-body {
	width:100%;
	margin-top: 10%; 
	display:flex;
	align-items: center;
	justify-content: center;
}

.section1 {
	width:50%;
	/* height:75%; */
	color:white;
}

.item-1 {
	font-size: 4.5em;
	font-family: lores-15;
}

.item-2 {
	font-size: 1.7em;
	font-family: consolas;
}

.s1-title {
	color:white;
	font-size:1.7em;
	height:15%;
	display: flex;
	align-items: center;
	font-family: consolas;
}


.whitelist-outer {
	width:100%;
	height:90vh;
	display:flex;
	align-items: center;
	justify-content: center;
}

.whitelist-main {
	width:60%;
	height:60%;
	/* background-color: powderblue; */
	display: flex;
	flex-direction:row;
}

.wlleft-side {
	width:50%;
	/* background-color: blue; */
}

.wlright-side {
	width:50%;
	display: flex;
	align-items: center;
	justify-content: center;
	/* background-color: red; */
}

.wlmiddle-line-holder {
	width:6%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.wlmiddle-line {
	width:15%;
	height:80%;
	background-color: var(--primary);
	border-radius: 50%;
}

.welcome {
	color:white;
	font-family: lores-15;
	font-size:5rem;
}

.intro-part2 {
	color:white;
	font-family: consolas;
	font-size:1.5rem;
	margin-top: 2%;
}

.mints-left {
	color:white;
	font-family: lores-15;
	font-size: 2em;
	width:100%;
	text-align: center;
}

.mint-price {
	color:white;
	font-family: lores-15;
	font-size: 2em;
	text-align: center;
	margin-top:3%;
}


.wlright-holder {
	width:75%;
	height: 50%;
}

.mint-button-holder {
	height:80%;
	display: flex;
	align-items: center;
	flex-direction: column-reverse;
	
}

.mint-button {
	background-color: rgba(0,0,0,0);
	color: var(--primary);
	border: 2px solid var(--primary);
	border-radius: 50px;
	padding-left: 8%;
	padding-right: 8%;
	font-size: 3em;
	font-family: consolas;
	transition-duration: .25s;
}

.mint-button:hover {
	cursor:pointer;
	background-color: var(--primary);
	color:white;
}

.error-box {
	height:40%;
	width:80%;
	font-family: consolas;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
}

.connected-main {
	width:100%;
	/* height:100vh; */
	display: flex;
	align-items: center;
	justify-content: center;
}


.not-connected-main {
	width:100%;
	height:80vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.not-connected-inner {
	width:50%;
	height:60%;
	/* background-color: blue;  */
}

.not-connected-title {
	color:var(--primary);
	font-family: lores-15;
	font-size:3em;
	text-align: center;
}

.not-connected-message-1 {
	color:white;
	font-family: consolas;
	font-size:2em;
	margin-top:5%;
	margin-bottom:5%;
}

.not-connected-message-2 {
	color:white;
	font-family: consolas;
	font-size:1.5em;
}


.join-steps-main {
	width:100%;
	height:90%;
	display: flex;
	/* align-items: center; */
	flex-direction: column;
	/* justify-content: center; */
	
}

.join-1 {
	font-family: lores-15;
	height:10%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 2em;
	margin-top:5%;
	/* background-color: blue; */
}

.joinstep-main {
	margin-top:3%;
	margin-bottom:3%;
	
}

.jstep-number {
	color:var(--primary);
	font-family: lores-15;
	font-size: 1.75em;
}

.jstep-text {
	color:white;
	font-family: consolas;
	font-size: 1.5em;
}


.join-steps-inner2 {
	font-family: consolas;
	font-size:1.5em;
	margin-top:5%;
	margin-bottom: 5%;
}

.join-steps-inner3 {
	font-family: consolas;
	font-size: 1.5em;
	/* margin-bottom:20%; */
}

.wl-info {
	color:var(--primary);
	font-family: lores-15;
	font-size: 1.5em;
}


.seper {
	width:100%;
	height:8px;
	background-color:var(--primary);
	border-radius: 50%;
	
	margin-top:5%;
	margin-bottom:5%;
}









