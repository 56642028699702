




.roadmap-body {
	display: flex;
	align-items: center;
	flex-direction: column;
	color:white;
}

.sign-holder {
	margin-top:2%;
	display: flex;
}

.ccc-sign {
	width:70%;
	height:auto;
	margin:auto;
}

.roadmap-title {
	font-size: 4em;
	margin-top:5%;
	margin-bottom:2%;
	
}

.roadmap-statement {
	width:60%;
	font-size:1.5em;
	margin-bottom: 1%;
}


.map-holder {
	width:100vw;
	height:1500px;
	display: flex;
	margin-top:8%;
}


.center-line {
	width:.5%;
	height:1000px;
	background-image:linear-gradient(var(--primary),rgba(88, 252, 236,.5));
	
	position:relative;
	top:100px;
	
}

.center_object {
	display: flex;
	align-items: center;
	flex-direction: column;
	
}

.outer-circle {
	width:35px;
	height:35px;
	border-radius: 50%;
	position: relative;
	background-color:var(--primary);
	
	display:flex;
	align-items: center;
}

.inner-circle {
	width:25px;
	height:25px;
	background-color: black;
	border-radius:50%;
	margin:auto;
	
	border:2px solid black;
	
	transition-duration: .5s;
}

.column {
	width:49.75%;
	height:100%;
	/* display: flex; */
	
}


.right-box {
	margin-left: 5%;
	margin-right: auto;
	border-radius: 50px;
	width:90%;
	height:200px;
	background-image:linear-gradient(45deg, #935be9, rgba(88, 252, 236,.5));
	position: relative;
	display: flex;
	align-items: center;
}

.left-box {
	border-radius: 50px;
	width:90%;
	height:200px;
	background-image:linear-gradient(45deg, #935be9, rgba(88, 252, 236,.5));
	position: relative;
	margin-left: auto;
	margin-right:5%;
	display: flex;
	align-items: center;
}

.box-inner {
	width:99.5%;
	height:99%;
	background-color: black;
	margin: auto;
	border-radius: 50px;
	/* display: flex; */
	/* flex-direction: column; */
}

.box-title  {
	font-size: 3em;
	width:100%;
	height:20%;
	display:flex;
}
.title-holder {
	padding-top:2%;
	margin: auto;
	font-size:3vw;
}

.box-description  {
	width:80%;
	height:80%;
	margin:auto;
	display: flex;
	align-items: center;
}
.description-holder {
	margin: auto;
	text-align: center;
	font-size: 2em;
}

#description-1 {
	font-size: 1vw;
}

#block-2 {
	top:250px;
}

#description-2 {
	font-size: .9vw;
}
#block-3 {
	top:300px;
}

#description-3 {
	margin-top:2%;
	font-size:.75vw;
}

#block-4 {
	top:550px;
}
#description-4 {
	font-size: .8vw;
}

#block-5 {
	top:600px;
}

#description-5 {
	font-size:.7vw;
}

















