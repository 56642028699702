@import "./fonts.css";




.gov-main-body {
	
	width:100%;
	height:80vh;
	color:white;
	
	display: flex;
	align-items: center;
	justify-content: center;
}

.gov-notification {
	width:40%;
	height:40%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.notifcation-title {
	font-size: 3em;
	font-family: lores-15;
	padding-bottom: 10%;
}

.notifcation-text {
	
	font-family: consolas;
	font-size:1.5em;
}