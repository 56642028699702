@import "./fonts.css";



:root {
	--primary: #935be9;
	--primary-hover:#9864e8;
}



html {
	background-color: black;
	margin: 0px;
	padding: 0px;
	overflow-x: hidden;
}
 
body {
	margin: 0px;
	z-index: -1;
}

figure {
	display: block;
	margin:0;
}

.main-div {
	height:100%;
}


.is-family-primary {
	font-family: consolas;
}

.is-family-secondary {
	font-family: lores-15;
}


/*
███    ██  █████  ██    ██ ██████   █████  ██████
████   ██ ██   ██ ██    ██ ██   ██ ██   ██ ██   ██
██ ██  ██ ███████ ██    ██ ██████  ███████ ██████
██  ██ ██ ██   ██  ██  ██  ██   ██ ██   ██ ██   ██
██   ████ ██   ██   ████   ██████  ██   ██ ██   ██
*/

/* ================================== */
/* ================================== */
/* vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv */

.navbar-body {
	width:100%;
	height:10vh;
}

.media-container {
	height: 100%;
	width:auto;
	display: flex;
	align-items: center;
	margin: auto;
}

.media-section {
	width:25%;
	height: 100%;
	display: flex;
	align-items: center;
	margin: auto;
	float: left;
}

.media-button {
	width:2vw;
	height:2vw;
	margin: 1vw;
	float: left;
}

.media-icon {
	width:100%;
	height:100%;
	
	transition-duration: .5s;
}

.media-icon:hover {
	cursor:pointer;
	transform: rotateZ(10deg) scale(1.05,1.05);
}


.logo-section {
	width:50%;
	height:100%;
	display: flex;
	align-items: center;
	float: left;
}

.logo-link {
	margin:auto;
}

.ccc-logo {
	width:20vw;
	
	margin: auto;
}

.ccc-logo:hover {
	cursor:pointer;
}

.nav-section {
	width:25%;
	height:100%;
	display: flex;
	align-items: center;
	float: left;
}

.nav-container {
	margin: auto; 
	display: flex;
	align-items:center;
}

.button-container {
	margin: 20px;
	float: left;
}

.nav-button {
	color:var(--primary);
	font-size: 1.15vw;
	background-color: rgba(0,0,0,0);
	border-width: 0px;
}

.nav-button:hover {
	cursor:pointer;
}

.underline {
	height:2px;
	width:100%;
	opacity:0;
	background-color:var(--primary);
	transition-duration: .5s;
}

.button-inner {
	padding-top: 1%;
	padding-left: 2%;
	padding-right: 3%;
	
	transition: all .2s ease-in-out;
}



.menu-button {
	margin-right: 10px;
	float: left;
	z-index:1;
}

.menu-icon {
	width:2vw;
	height:2vw;
	
}
 
.menu-icon:hover {
	cursor: pointer;
}

.drop-menu {
	width:10vw;
	
	position: absolute;
	border-radius: 10px ;
	
	top: 7vh;
	background-color: rgb(20,20,20);
	float: right;
	z-index: 1
}


.drop-item {
	width:100%;
	height:50px;
	display: flex;
	align-items: center;
	margin-top:2%;
	margin-bottom:2%;
	transition-duration: .3s;
	color:var(--primary);
}


.drop-item:hover {
	transform: translateX(2%);
	cursor: pointer;
}

.drop-inner {
	font-size: 1.5vw;
	margin-left: 10%;
}



/* ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ */
/* ================================== */
/* ================================== */

/*
██     ██  █████  ██      ██      ███████ ████████     ███    ███ ███████ ███    ██ ██    ██
██     ██ ██   ██ ██      ██      ██         ██        ████  ████ ██      ████   ██ ██    ██
██  █  ██ ███████ ██      ██      █████      ██        ██ ████ ██ █████   ██ ██  ██ ██    ██
██ ███ ██ ██   ██ ██      ██      ██         ██        ██  ██  ██ ██      ██  ██ ██ ██    ██
 ███ ███  ██   ██ ███████ ███████ ███████    ██        ██      ██ ███████ ██   ████  ██████
*/

/* ================================== */
/* ================================== */
/* vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv */



.wMenu-body {
	width:11vw;
	color:white;
	background-color: rgb(20,20,20);
	position:absolute;
	top:7vh;
	right:5vw;
	display: flex;
	flex-direction: column;
	z-index: 1;
	border-radius: 10px;
	/* padding-top: .5%;
	padding-bottom: .5%; */
}


.wallet-item {
	height:50px;
	display: flex;
	align-items: center;
	flex-direction: row;
	transition-duration:.25s;
	margin-left:3%;
	margin-top:5%;
	margin-bottom:5%;
}

.wallet-item:hover {
	cursor: pointer;
	transform: translateX(10px);
}

.wallet-image {
	width:50px;
	height:50px;
	margin:5%;
}
.wallet-icon {
	width:100%;
	height:100%;
}

.wallet-name {
	font-size:1em;
	
}

.m-holder {
	display: flex;
	align-items: center;
	height:50%;
}

.address-holder{
	color:rgb(200,200,200);
	margin:5px;
	font-size: 1.15vw;
	text-align: right;
	position: absolute;
}



/* ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ */
/* ================================== */
/* ================================== */


/*
███████  ██████   ██████  ████████ ███████ ██████
██      ██    ██ ██    ██    ██    ██      ██   ██
█████   ██    ██ ██    ██    ██    █████   ██████
██      ██    ██ ██    ██    ██    ██      ██   ██
██       ██████   ██████     ██    ███████ ██   ██
*/

/* ================================== */
/* ================================== */
/* vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv */

.footer-main-body {
	height:10vw;
	width:100vw;
	
	position:relative;
	bottom:0;
	display: flex;
	align-items: center;
	/* flex-direction: row; */
	
	background-image:linear-gradient(rgb(10,10,10), black);
}

.footer-inner-body {
	height:100%;
	width:70%;
	display: flex;
	
	margin: auto;
}

.left-side {
	width:10%;
	height:100%;
	
	display: flex;
	align-items: center;
	float: left;
}

.footer-image {
	height:50%;
	margin:auto;
}

.middle-side {
	height:100%;
	width: 60%;
	color:white;
	display: flex;
	align-items: center;
	
	margin-left: 20%;
	margin-right: 20%;
	float: left;
}

.footer-nav {
	display: flex;
	align-items: center;
	margin:auto;
}

.footer-nav-item {
	font-size: 1.5vw;
	margin:1.1vw;
	color:white;
}


.right-side {
	height:100%;
	width:auto;
	display:flex;
	align-items: center;
	flex-direction: row;
	/* float: left; */
}


.footer-icon {
	height:3vw;
	width:3vw;
	margin: 15px;
	float: left;
}

.footer-icon:hover {
	cursor: pointer;
}

/* ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ */
/* ================================== */
/* ================================== */



.video-container {
	
	width:100vw;
	height:100vh;
	/* background-color: black; */
	position:absolute;
	top:0;
	background: linear-gradient(#30515d,#30515d);
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: center;
	
}


.intro-video {
	
	/* width:100%; */
	height:100%;
}







