@import "./fonts.css";

:root {
	--primary: #935be9;
	--primary-hover:#9864e8;
}



/* BANNER */
/* ================================== */ 
/* ================================== */
/* vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv */



.banner-container {
	/* width:100%;
	height:90vh; */
	/* display: block; */
}


.banner {
	width:100%;
	position: relative;
	z-index: -1;
}


/* ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ */
/* ================================== */
/* ================================== */


/* BODY */
/* ================================== */
/* ================================== */
/* vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv */

/*
██    ██ ██████  ██████  ███████ ██████      ██████   █████   ██████  ███████
██    ██ ██   ██ ██   ██ ██      ██   ██     ██   ██ ██   ██ ██       ██
██    ██ ██████  ██████  █████   ██████      ██████  ███████ ██   ███ █████
██    ██ ██      ██      ██      ██   ██     ██      ██   ██ ██    ██ ██
 ██████  ██      ██      ███████ ██   ██     ██      ██   ██  ██████  ███████
*/


.upper {
	width:100%;
	/* height: 1000px; */
}

.body-inner {
	margin-top:5%;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.survivor-count {
	color:white;
	font-size: 4vw;
	margin:auto;
	
}

.description-one {
	width:40vw;
	color: white;
	font-size: 1.2vw;
	margin-top:5%;
	
	
}


.opensea-portion {
	color:white;
	font-size:1.5vw;
	display: flex;
	align-items: center;
	flex-direction: column;
	width:20%;
}

.ops-text {
	width:100%;
	margin-top: 40%;
	font-size: 1.5em;
	text-align: center;
}

.ops-button-holder {
	width:100%;
}

.ops-button {
	margin-top: 10%;
	font-size: 2.4em;
	background-color: black;
	color:var(--primary);
	border:2px solid var(--primary);
	border-radius: 50px;
	width:100%;
	transition-duration: .25s; 
}

.ops-button:hover {
	background-color: var(--primary);
	color:white;
	cursor:pointer;
}

.ops-inner {
	/* margin-left: 5%; 
	margin-right: 7%; */
}

.the-awakening {
	display: flex;
	align-items: center;
	flex-direction: column;
	margin-top: 10%;
	margin-left:auto;
	margin-right: auto;
}

.awake-clock {
	width:60%;
	position: absolute;
	
	z-index: -1
}

.awake-text {
	color:white;
	font-size: 7vw;
	margin-bottom:2%;
}

.time-holder {
	margin:auto;
}

.clock-main {
	
	background-image: url('../images/alarm-clock.png');
	background-size: contain;
	background-position:center;
	background-repeat: no-repeat;
	
	width:60vw;
	height:40vh;
	display: flex;
	align-items: center;
}


.copy-section {
	display: flex;
	align-items: center;
	flex-direction: column;
	margin-top:10%;
	margin-bottom: 10%;
	color:white;
	height:100%;
	width:90%;
	
	position:relative;
}


/*
██████   █████  ████████ ██   ██ ██████   ██████   ██████  ███    ███
██   ██ ██   ██    ██    ██   ██ ██   ██ ██    ██ ██    ██ ████  ████
██████  ███████    ██    ███████ ██████  ██    ██ ██    ██ ██ ████ ██
██   ██ ██   ██    ██    ██   ██ ██   ██ ██    ██ ██    ██ ██  ██  ██
██████  ██   ██    ██    ██   ██ ██   ██  ██████   ██████  ██      ██
*/


.bathroom-holder {
	width:70%;
	height:50vw;
	
	background-image: url('../images/bathroom-3.gif');
	background-size:cover;
}

.b-image {
	height:100%;
}

.box-holder{
	width:100%;
	height:100%;
	position:absolute;
	top:0;
	left:0;
}

.then-box {
	width:10%;
	height:19%;
	/* background-color: white; */
	
	position:relative;
	left:44%;
	top:23%;
}
.then-box:hover {
	cursor:pointer;
}

.now-box {
	width:8%;
	height:18%;
	
	position:relative;
	left:59%;
	top:5%;
	/* background-color: white; */
}
.now-box:hover {
	cursor:pointer;
}

.then-section {
	position: relative;
	top:0;
	width:100%;
	height:100%;background-color: rgba(0,0,0,.5);
	
	display:flex;
	align-items: center;
	
	
}

.then-close-icon {
	position:absolute;
	top:10%;
	right:10%;
	width:50px;
	height:50px;
	
	transition-duration: .25s;
}

.then-close-icon:hover {
	transform: scale(1.1,1.1);
	cursor: pointer;
}

.then-inner {
	margin:auto;
	width:80%;
	height:60%;
	/* background-color:rgb(10,10,10); */
	
	display: flex;
	flex-direction: column;
}

.then-title {
	margin:auto;
	font-size:3vw;
}

.then-text {
	height:80%;
	width:90%;
	margin:auto;
	display: flex;
	align-items: center;
	font-size: 1.23vw;
}



.now-section {
	position: relative;
	top:0;
	width:100%;
	height:100%;background-color: rgba(0,0,0,.5);
	
	display:flex;
	align-items: center;
	
	
}

.now-close-icon {
	position:absolute;
	top:10%;
	right:10%;
	width:50px;
	height:50px;
	
	transition-duration: .25s;
}

.now-close-icon:hover {
	transform: scale(1.1,1.1);
	cursor: pointer;
}

.now-inner {
	margin:auto;
	width:80%;
	height:60%;
	/* background-color:rgb(10,10,10); */
	
	display: flex;
	flex-direction: column;
}

.now-title {
	margin:auto;
	font-size:3vw;
}

.now-text {
	height:80%;
	width:90%;
	margin:auto;
	display: flex;
	align-items: center;
	font-size: 1.23vw;
}


/*
 ██████  ██████  ██    ██ ███    ██  ██████ ██ ██
██      ██    ██ ██    ██ ████   ██ ██      ██ ██
██      ██    ██ ██    ██ ██ ██  ██ ██      ██ ██
██      ██    ██ ██    ██ ██  ██ ██ ██      ██ ██
 ██████  ██████   ██████  ██   ████  ██████ ██ ███████
*/


.shadow-council {
	width:100%;
	height:50%;
	color:white;
	

	
	display: flex;
	align-items: center;
	flex-direction: column;
}

.council-title {
	font-size: 3.2em;
}

.council-sub {
	margin-top:10px;
	font-size: 1.25em;
}

.council-chairs {
	padding-top:5%;
	display: flex;
	align-items: center;
	
}

.council-seat {
	width:22vw;
	height:30vw;
	background-color: grey;
	margin-left: 2%;
	margin-right:2%;
	margin-bottom:10%;
	
	backface-visibility: hidden;
	
	display: flex;
	align-items: center;
	flex-direction:column; 
	border-radius: 20px;
	background-image: linear-gradient(black,var(--primary) 100%);
	
	transition-duration: .4s;
}

.council-seat:hover {
	transform: rotateZ(3deg);
	
}

.seat-title {
	margin-top:3%;
	font-size: 2vw;
	font-family: lores-15; 
	
}

.chairman-image {
	margin-top: 10%;
	margin-bottom: 10%;
	width: 100%;
	height:50%;
	background-image: url('../images/the-chairman-shade-round.png');
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	transition-duration: .5s;

}

.ceo-image {
	margin-top: 10%;
	margin-bottom: 10%;
	width: 100%;
	height:50%;
	background-image: url('../images/the-ceo-shade-round.png');
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;

	transition-duration: .5s;
}

.president-image {
	margin-top: 10%;
	margin-bottom: 10%;
	width: 100%;
	height:50%;
	background-image: url('../images/the-president-shade-round.png');
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;

	transition-duration: .5s;
}

.director-image {
	margin-top: 10%;
	margin-bottom: 10%;
	width: 100%;
	height:50%;
	background-image: url('../images/the-director-shade-round.png');
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;

	transition-duration: .5s;
}

.seat-text {
	text-align: center;
	font-size: 1.2vw;
	font-family: consolas;
	padding-left: 5%;
	padding-right: 5%;
}


/* ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ */
/* ================================== */
/* ================================== */