@import url("https://use.typekit.net/iar8wbr.css");



@font-face {
	font-family: lores-15;
	font-weight: 700;
	font-style: normal;
}


/* consolas font */
@font-face {
	font-family: consolas;
	src: local(consolas),
 url("../fonts/consolas/CONSOLA.TTF") format("truetype");

}


@font-face {
	font-family: consolas;
	src: local(consolas),
	url("../fonts/consolas/CONSOLAB.TTF") format("truetype");
	font-weight: bold;
}




/* @font-face {
  font-family: consolas;
  src: url("./fonts/consolas/CONSOLAB.ttf");
	font-weight:bold;
} */
 /* @font-face {
  font-family: consolas;
  src: url("../fonts/consolas/consolai.tff");
	font-weight: bold;
	font-style: italic,oblique;
} */
/* --------------- */ 
















