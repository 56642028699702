@import "../../css_files/fonts.css";


.main-clock-body {
		color:white;
		font-size: 2.3vw;
		display: flex;
		align-items: center;
		font-family: lores-15;
		
		/* background-color: rgb(70,70,70); */
}

.leaf-group {
	margin-left: 5px; 
}

.leaf-group-bottom,
.leaf-group-top
 {
	display: inline-flex;
	flex-direction: column;
	margin: auto;
}

.leaf-group-top {
	position: absolute;
}


.top-leaf, .bottom-leaf,
.leaf-group.flip::after,
.leaf-group.flip::before

{
	height:.25em;
	line-height: 1;
	width:20px;
	padding:.25em;
	overflow: hidden;
}


.top-leaf
{
	background-color: rgb(51, 51, 51);
	border-bottom:1px solid rgba(200,200,200,.2);
	border-top-right-radius:.5rem;
	border-top-left-radius:.5rem;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.bottom-leaf {
	background-color: rgb(43, 43, 43);
	display: flex;
	align-items: center;
	flex-direction: column-reverse;
	border-bottom-right-radius:.5rem;
	border-bottom-left-radius:.5rem;
	
}


.leaf-group.flip::before {
	position:absolute;
	content: '7';
	background-color:rgb(30,30,30);
	
	
	
	animation-name:  fliptop;
	animation-duration:  .5s;
	animation-timing-function: ease-in;
	transform-origin: bottom;
}

@keyframes fliptop {
	100% {
		transform: rotateX(90deg);
	}
	
}


.time-title {
	display: flex;
	align-items: center;
}

.title-inner {
	margin:auto;
	font-size:.5em;
}

.group-inner {
	display: flex;
	margin: 12px;
}



#days-l1-top-leaf {
	width:auto;
}
#days-l1-bottom-leaf {
	width:auto;
}

#days-l2-top-leaf {
	width:auto;
}
#days-l2-bottom-leaf {
	width:auto;
}


@media screen and (max-width:1200px) {
	.group-inner{
		margin: 5px;
	}
}


@media screen and (max-width:950px) {
	.group-inner{
		margin: 2px;
	}
}






